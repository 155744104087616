const util = {
    validateEmail: function(email) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return true;
        }
        return false;
    },
    toggleError: function(element, valid) {
        if (valid) {
            element.classList.remove('error');
            return;
        }
        element.classList.add('error');
    },
    toggleErrorMessage: function(element, valid, message) {
        if (valid) {
            element.textContent = '';
            return;
        }
        element.textContent = message;
    },
    toggleInputError(element, valid, message) {
        this.toggleError(element, valid);
        this.toggleErrorMessage(element, valid, message);
    },
    scrollTo(target) {
        document.querySelector(target).scrollIntoView({ behavior: "smooth" });
    }
}

export default util