import "./app.css";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";

import Home from "./pages/home";

import ScrollToTop from "./components/scrollToTop";
import Footer from './components/footer.js';

import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faPaperPlane,
    faChevronDown,
    faDiagramProject,
    faTrophy,
    faListCheck,
    faAward,
    faHouse,
    faHomeUser,
    faLocationDot,
    faCircle,
    faLock,
    faBars,
    faXmark,
    faCircleInfo,
    faQuestion
} from "@fortawesome/free-solid-svg-icons";

import {
    faUser,
    faBuilding,
    faCircleQuestion,
    faCircleXmark,
    faClock as regularClock,
    faPaperPlane as regularPaperPlane
} from "@fortawesome/free-regular-svg-icons";

import {
    faFacebook,
    faYoutube,
    faTiktok,
    faInstagram,
    faGithub,
    faSalesforce,
    faReddit,
    faMeetup,
    faLinkedin,
    faBlogger
} from '@fortawesome/free-brands-svg-icons';

library.add(
    faLock,
    faBars,
    faXmark,
    faLocationDot,
    faCircle,
    faPaperPlane,
    faChevronDown,
    faDiagramProject,
    faBuilding,
    faTrophy,
    faListCheck,
    faAward,
    faUser,
    faHouse,
    faHomeUser,
    regularClock,
    regularPaperPlane,
    faFacebook,
    faYoutube,
    faTiktok,
    faInstagram,
    faReddit,
    faMeetup,
    faLinkedin,
    faGithub,
    faSalesforce,
    faBlogger,
    faCircleInfo,
    faCircleQuestion,
    faQuestion,
    faCircleXmark
);

const App = () => {
    return (
        <Router basename="/">
            <ScrollToTop>
                <div className="app">
                    <header className="header">
                    </header>

                    <Routes>
                        <Route exact path="/" element={<Home />}></Route>
                        <Route path="*" element={<Navigate to="/" />}/>
                    </Routes>

                    <footer>
                        <Footer />
                    </footer>
                </div>
            </ScrollToTop>
        </Router>
    );
};

export default App;
