import React from 'react'
import PropTypes from "prop-types";

const Spinner = ({ props }) => {

    let style = `spinner is-relative ${props.size} ${props.type}`;

    return (
        <div className={style}></div>
    );
}

Spinner.propTypes = {
    size: PropTypes.string,
    type: PropTypes.string
}

export default Spinner;