import React, { useState, useEffect} from "react";
import PropTypes from "prop-types";

import { Swiper, SwiperSlide } from "swiper/react";
import { ReactComponent as Shutter }  from '../assets/svg/shutter.svg';

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// import required modules
import { Zoom, FreeMode, Navigation, Thumbs, Pagination } from "swiper";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Carousel = ({ props }) => {
    const [thumbsSwiper, setThumbsSwiper] = useState(null);

    let swiperItems = [];

    useEffect(() => {
        const handleKeydown = event => {
            if (event.key === "Escape") {
                props.onClose(null);
            }
        };
      
        document.addEventListener("keydown", handleKeydown, false);
      
        return () => {
            document.removeEventListener("keydown", handleKeydown);
        };
    });

    props.items?.forEach((item) => {
        let swiperItem = Object.assign({}, item);

        swiperItem.url = swiperItem.image_name
          ? require(`../assets/photos/${swiperItem.image_name}`) : "";

        swiperItems.push(swiperItem);
    });

    function closeCarousel(event) {
        props.onClose(event);
    }

    function toggleStory() {
        document.querySelector(".gallery-carousel.active .swiper-slide-active .image-story")
            .classList.toggle('active');
        
        document.querySelectorAll('.gallery-carousel.active .swiper-view img')
            .forEach(image => {
                let story = image.nextElementSibling;
                let ratio = getContainedSize(image);
                story.style.width = `${ratio.width}px`;
            });
    }
    
    function getContainedSize(img) {

        var ratio = img.naturalWidth / img.naturalHeight;
        var width = img.height * ratio;
        var height = img.height;

        if (width > img.width) {
            width = img.width
            height = img.width/ratio
        }

        return { width: width + 2, height: height };
    }
    
    return (
        <>
            <Swiper
                style={{
                    "--swiper-navigation-color": "#fff",
                    "--swiper-pagination-color": "#fff",
                }}
                loop={true}
                zoom={true}
                pagination={true}
                navigation={true}
                thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
                modules={[Zoom, FreeMode, Navigation, Thumbs, Pagination]}
                className="swiper-view">
                
                {swiperItems.map((item) => {
                    return (
                        <SwiperSlide key={item.id} onClick={toggleStory}>
                            <img src={item.url} alt={item.title} />
                            <div className="image-story">
                                <div className="story-content">
                                    <h1>{item.title}</h1>
                                    <h2><Shutter className="icon-shutter" /> {item.location}</h2>
                                    <p>{item.description}</p>
                                </div>
                                {/* <FontAwesomeIcon icon="fa-regular fa-circle-xmark" size="xl" className="story-content-close" onClick={toggleStory} /> */}
                            </div>
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            <Swiper
                onSwiper={setThumbsSwiper}
                loop={true}
                freeMode={true}
                slidesPerView={4}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className="swiper-thumbs">
                    
                {swiperItems.map((item) => {
                    return (
                        <SwiperSlide key={item.id}>
                            <img src={item.url} alt={item.title} />
                        </SwiperSlide>
                    );
                })}
            </Swiper>
            <div className="swiper-background"></div>
            <div className="swiper-action">
                <div onClick={toggleStory}><FontAwesomeIcon icon="fa-solid fa-circle-info" size="xl" /></div>
                <div onClick={closeCarousel}><FontAwesomeIcon icon="fa-solid fa-xmark" size="xl" /></div>
            </div>
        </>
    );
};

Carousel.propTypes = {
    items: PropTypes.string
};

export default Carousel;
