import React, { useEffect } from 'react';

import './home.css';
import '../assets/style.css';

import util from '../components/util.js';
import about from '../assets/static/data/about.json';
import messages from '../assets/static/data/messages.json';

import Contact from '../components/contact.js';
import Gallery from "../components/gallery.js";
import PhotoOfTheWeek from "../components/photooftheweek.js";

import { ReactComponent as JaysonF } from "../assets/jaysonf.svg";
import { ReactComponent as Watermark } from "../assets/watermark.svg";
import { ReactComponent as SlideDown } from "../assets/svg/slide-down.svg";

const Home = (props) => {

    useEffect(() => {
        let signature = document.querySelector('.signature');
        signature.classList.add("active");

        const interval = setInterval(() => {
            signature.classList.remove('active');

            setTimeout(() => {
                signature.classList.add("active");
            }, 250);
        }, 8000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="home">
            <section className="banner banner1">
                <div className="content">
                    <div className="signature">
                        <JaysonF/>
                    </div>
                </div>
                <div className="scroll bounce">
                    <SlideDown onClick={() => {
                        util.scrollTo('.banner2');
                    }}/>
                </div>
            </section>
            <section className="banner banner2">
                <div className="content">
                    <div className="watermark">
                        <Watermark />
                    </div>
                    <div className="message">
                        <h2 data-aos="fade-up"
                            data-aos-duration="500"
                            data-aos-easing="ease-in-sine"
                            dangerouslySetInnerHTML={{ __html: about.brand_essence }}>
                        </h2>
                        <p data-aos="fade-up"
                            data-aos-duration="500"
                            data-aos-easing="ease-in-sine">
                            {about.welcome_message}
                        </p>
                        <p data-aos="fade-up"
                            data-aos-duration="500"
                            data-aos-easing="ease-in-sine">
                            {about.welcome_location}
                        </p>
                        <p data-aos="fade-up"
                            data-aos-duration="500"
                            data-aos-easing="ease-in-sine">
                            {about.welcome_goal}
                        </p>
                        <p data-aos="fade-up"
                            data-aos-duration="500"
                            data-aos-easing="ease-in-sine">
                            {about.welcome_invitation}
                        </p>
                    </div>
                </div>
            </section>
            <section className="home-gallery">
                <div>
                    <PhotoOfTheWeek />

                    <h1 data-aos="fade-up"
                        data-aos-duration="500"
                        data-aos-easing="ease-in-sine">
                        {messages.gallery_gear_title}
                    </h1>

                    <p data-aos="fade-up"
                       data-aos-duration="700"
                       data-aos-easing="ease-in-sine"
                        dangerouslySetInnerHTML={{ __html: messages.gallery_gear_statement }}>
                    </p>

                    <h1 data-aos="fade-up"
                        data-aos-duration="500"
                        data-aos-easing="ease-in-sine">
                        {messages.gallery_setup_title}
                    </h1>

                    <p data-aos="fade-up"
                       data-aos-duration="700"
                       data-aos-easing="ease-in-sine"
                       dangerouslySetInnerHTML={{ __html: messages.gallery_setup_statement }}>
                    </p>

                    <h1 data-aos="fade-up"
                        data-aos-duration="500"
                        data-aos-easing="ease-in-sine">
                        {messages.gallery_title}
                    </h1>
                    <p data-aos="fade-up"
                       data-aos-duration="700"
                       data-aos-easing="ease-in-sine"
                       dangerouslySetInnerHTML={{ __html: messages.gallery_statement }}>
                    </p>
                    <Gallery />
                </div>
            </section>
            <section className="contact-me">
                <div>
                    <h1 data-aos="fade-up"
                        data-aos-duration="500"
                        data-aos-easing="ease-in-sine">How can I help?</h1>

                    <p data-aos="fade-up"
                       data-aos-duration="500"
                       data-aos-easing="ease-in-sine"
                       dangerouslySetInnerHTML={{ __html: messages.contact_me }}>
                    </p>
                    <Contact className="contact" />
                </div>
            </section>
        </div>
    );
};

Home.propTypes = {};

export default Home;