import React from "react";
import PropTypes from "prop-types";

import Spinner from '../components/spinner.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const button = ({ props }) => {

    let btnClass = `btn ${props.type}`;
    let btnIconClass = `btn-icon ${props.icon ? (props.icon) : 'hide'}`;

    let onclick = props.handleClick;

    let btnLoader = props.showLoader
        ? (<span className="btn-loader"><Spinner props={{ size: 'xs', type: '' }} /></span>) : '';

    let btnIcon = props.icon
        ? <FontAwesomeIcon icon={props.icon} className={btnIconClass} /> : '';

    return (
        <button className={btnClass} disabled={props.disable} onClick={event => { onclick(props.data) }}>
            {props.label}
            {btnIcon}
            {btnLoader}
        </button>
    );
};

button.propTypes = {
    showLoader: PropTypes.bool,
    type: PropTypes.string,
    label: PropTypes.string,
    icon: PropTypes.string,
    disable: PropTypes.bool
};

export default button;
