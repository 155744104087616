/* eslint-disable jsx-a11y/img-redundant-alt */
import React from 'react';
import photo from "../assets/static/data/photooftheweek.json";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as Shutter }  from '../assets/svg/shutter.svg';

const PhotoOfTheWeek = () => {
    let photoUrl = require(`../assets/photos/photooftheweek/${photo.image}`);

    return (
        <div className="photo-of-week">
            <h1>Photo of the Week</h1>
            <div className="photo-of-week_title">
                <h2>{photo.title}</h2>
                <h3>{photo.subtitle}</h3>
            </div>
            <img src={photoUrl} alt="Photo of the Week" />
            <div className="photo-of-week_meta">
                <p className="location"><FontAwesomeIcon icon="fa-solid fa-location-dot" size="xs" className="icon-map-pin" />&nbsp; {photo.location}</p>
                <p className="specs"><Shutter className="icon-shutter" size="xs" />&nbsp;  {photo.specs}</p>
            </div>
        </div>
    )
}

PhotoOfTheWeek.propTypes = {}

export default PhotoOfTheWeek;