import React from 'react';

import galleries from '../assets/static/data/gallery.json';
import Carousel from "../components/carousel.js";

const Gallery = ({ props }) => {

    let backgroundUrls = {};

    galleries.forEach((item) => {
        backgroundUrls[item.title] = item.image_name ? require(`../assets/photos/${item.image_name}`) : '';
    });

    function showGallery(event) {
        event.target.nextElementSibling
            .classList.add('active');

        document.body.style.overflow = 'hidden';
    }

    function closeCarousel(event) {
        let target = event ? event.target.closest(".gallery-carousel") : document.querySelector('.gallery-carousel.active');

        target?.classList.remove('active');
        document.body.style.removeProperty('overflow');
    }

    return (
        <div className="gallery">
            {
                galleries.map(item => {
                    return (
                        <div key={item.title}>

                            <div className="gallery-item"
                                 onClick={showGallery}
                                 data-aos="fade-up"
                                 data-aos-duration="500"
                                 data-aos-easing="ease-in-sine">

                                <img src={backgroundUrls[item.title]} alt={item.title} />
                                <h1>{item.title}</h1>
                            </div>

                            <div className="gallery-carousel">
                                <Carousel props={{ items: item.carousel, onClose: closeCarousel }} />
                            </div>
                        </div>
                    );
                })
            }
        </div>
    )
}

Gallery.propTypes = {};

export default Gallery;