import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Footer = () => {
    return (
        <div className="footer is-relative">
            <div className="rights-reserved">
                <p>©&nbsp;&nbsp;2023 JaysonF</p>
                <p>React JS | Figma | Canon</p>
                <p>Adobe Photoshop | Adobe Lightroom | DxO | Topaz</p>
                <p><FontAwesomeIcon icon="fa-solid fa-location-dot" size="xs" className="icon-map-pin" />&nbsp;&nbsp;&nbsp;Auckland, New Zealand</p>
            </div>
        </div>
    )
}


export default Footer;