import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

import avatar from '../assets/images/avatar.png';
import Button from '../components/button';

import messages from '../assets/static/data/messages.json';
import util from '../components/util';
import './contact.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import emailjs from 'emailjs-com';

const Contact = ({ props }) => {

    const [contact, setContact] = useState({
        didSend: false,
        loading: false,
        sending: false
    });

    useEffect(() => {
        let inputs = document.querySelectorAll('input, select, textarea');

        inputs.forEach(input => {
            input.addEventListener('focusout', () => {
                if (contact.didSend) {
                    isInputValid(input);
                }
            });
        });
    });

    const navigate = useNavigate();

    function send() {
        setContact({ ...contact, didSend: true, loading: true, sending: true });

        if (hasErrors()) {

            setTimeout(() => {
                setContact({ ...contact, loading: false, sending: false });
            }, 550);

            return;
        }

        // process submit
        emailjs.send(
            process.env.REACT_APP_EMAILJS_SERVICE_ID,
            process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
            {
                name: document.querySelector('input[name="name"').value,
                email: document.querySelector('input[name="email"').value,
                message: document.querySelector('textarea[name="message"').value,
            },
            process.env.REACT_APP_EMAILJS_USERID
        ).then( result => {

            console.log(result.text);

            setTimeout(() => {
                setContact({ ...contact, loading: false, sending: false });

                navigate('/result',
                    {
                        state: {
                            logo: 'email-sent.gif',
                            title: messages.form_success_title,
                            subtitle: messages.form_success_subtitle,
                            message: messages.form_success_message
                        }
                    });
            }, 1500);
        });
    }

    function hasErrors() {
        let hasError = false;

        let inputs = document.querySelectorAll('input, select, textarea');

        inputs.forEach(input => {
            let valid = isInputValid(input);

            // invalid
            if (!hasError && !valid) {
                hasError = true;
            }
        });

        return hasError;
    }

    function isInputValid(input) {
        let isValid = true;
        let parent = input.parentElement;

        util.toggleError(input.nextElementSibling, true);

        // Required input validation
        if (input.required) {
            let blank = input.required && !input.value;
            util.toggleError(parent, !blank);

            if (blank) isValid = false;
        }

        // Input specific validation
        if (input.type === 'email' && input.value) {
            let validEmail = util.validateEmail(input.value);

            util.toggleError(parent, validEmail);
            util.toggleInputError(input.nextElementSibling, validEmail, messages.form_invalid_email);

            if (isValid && !validEmail) {
                isValid = false;
            }
        }

        return isValid;
    }

    return (
        <div data-aos="zoom-in"
             data-aos-duration="500"
             data-aos-easing="ease-in-sine"
             className="contact">

            <div className="avatar" >
                <h2>Send me a message...</h2>
                <img src={avatar} alt="jaysonf"/>
            </div>

            <div className="form">
                <div className="form-instructions">
                    <div>
                        Fill in the form below and I'll get back to you as soon as I can.
                    </div>
                    <div>
                        <p>jayson@forceguru.io <FontAwesomeIcon icon="fa-regular fa-paper-plane" /></p>
                        <p>9:00am - 5:00pm <FontAwesomeIcon icon="fa-regular fa-clock" /></p>
                    </div>
                </div>
                <div className="inputs">
                    <div className="inputs-heading">
                        <h2>Contact Details</h2>
                        <img src={avatar} alt="jaysonf" className="avatar-mob"/>
                    </div>
                    <div>
                        <label>Name <abbr>*</abbr></label>
                        <input name="name" type="text" required></input>
                        <span></span>
                    </div>
                    <div>
                        <label>Email <abbr>*</abbr></label>
                        <input name="email" type="email" required></input>
                        <span></span>
                    </div>
                    <div>
                        <label>Message</label>
                        <textarea name="message" cols="40" rows="8"></textarea>
                        <span></span>
                    </div>
                </div>
                <div className="form-action">
                    <div className="btn-send">
                        <Button
                            props={{
                                showLoader: contact.loading,
                                type: "brand",
                                label: "Send",
                                icon: "",
                                data: "",
                                disable: contact.sending,
                                handleClick: send,
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

Contact.propTypes = {
    showAvatar: PropTypes.bool,
};

export default Contact;
